// src/CodeEditor.js
import React, { useState } from 'react';
import MonacoEditor from 'react-monaco-editor';
import axios from 'axios';
import { FaCopy, FaPlay } from 'react-icons/fa'; // Icons for buttons

const CodeEditor = () => {
  const [code, setCode] = useState('// Write some JavaScript code here...');
  const [output, setOutput] = useState('');
  const [errors, setErrors] = useState('');
  const [loading, setLoading] = useState(false);
  const [solutions, setSolutions] = useState([]);

  // Handle code changes in Monaco editor
  const handleEditorChange = (newCode) => {
    setCode(newCode);
  };

  // Redirect console.log() to capture outputs
  const captureOutput = () => {
    const originalLog = console.log;
    let capturedOutput = '';
    
    console.log = (message) => {
      capturedOutput += message + '\n'; // Capture log messages
      setOutput(capturedOutput); // Update the state with the captured output
    };

    return originalLog; // Return the original console.log so we can restore it later
  };

  // Run the code and capture output or errors
  const runCode = () => {
    setOutput('');
    setErrors('');
    const originalLog = captureOutput();

    try {
      // Try to run the code (avoid eval() when possible in real-world applications)
      new Function(code)(); // Use `Function` constructor to run the code
    } catch (error) {
      setErrors(error.message);
      searchStackOverflow(error.message);
    }

    // Restore the original console.log
    console.log = originalLog;
  };

  // Search StackOverflow for solutions based on the error
  const searchStackOverflow = async (query) => {
    setLoading(true);
    try {
      const response = await axios.get('https://api.stackexchange.com/2.3/search', {
        params: {
          order: 'desc',
          sort: 'relevance',
          intitle: query,
          site: 'stackoverflow',
        },
      });

      // Check if results are found
      if (response.data.items && response.data.items.length > 0) {
        setSolutions(response.data.items.map(item => ({
          title: item.title,
          snippet: item.body || "No solution snippet available", // Show full body if available
          link: item.link,
        })));
      } else {
        setSolutions([{ title: 'No solutions found', snippet: 'No related StackOverflow posts were found.', link: '#' }]);
      }
    } catch (error) {
      console.error('Error fetching StackOverflow solutions:', error);
      setSolutions([{ title: 'Error fetching solutions', snippet: 'There was an issue fetching solutions.', link: '#' }]);
    }
    setLoading(false);
  };

  // Copy solution snippet to clipboard
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        alert('Solution copied to clipboard!');
      })
      .catch((err) => {
        alert('Failed to copy text: ' + err);
      });
  };

  return (
    <div className="app">
      {/* Navbar */}
      <nav className="navbar">
        <h2 className="navbar-title">Scrap</h2>
        <div className="navbar-links">
          <a href="https://Scrap.icu" className="navbar-link">Home</a>
          <a href="https://scrap.icu/how.html" className="navbar-link">How to use Scrap</a>
          <a href="https://scrap.icu/about.html" className="navbar-link">About</a>
        </div>
      </nav>

      <div className="editor-container">
        <div className="editor-wrapper">
          {/* Monaco Editor */}
          <div className="editor-left">
            <MonacoEditor
              height="300px"
              language="javascript"
              theme="vs-dark"
              value={code}
              onChange={handleEditorChange}
              options={{
                minimap: { enabled: false },
                automaticLayout: true,  // Important for layout issues
                scrollBeyondLastLine: false,
                fontSize: 16,  // Set font size
                fontFamily: "Fira Code, monospace",  // Set font family (optional)
                fontLigatures: true,  // Optional: enable ligatures
                lineHeight: 24, // Set line height to ensure comfortable reading
                wordWrap: "on", // Enable word wrapping
                wrappingIndent: "same", // Make sure wrapping is properly indented
              }}
            />
          </div>

          {/* Below Monaco Editor: Run Code, Output, and Solutions */}
          <div className="editor-right">
            <div className="controls">
              <button className="run-btn" onClick={runCode} disabled={loading}>
                <FaPlay /> {loading ? 'Searching for solutions...' : 'Run Code'}
              </button>
            </div>

            <div className="output-container">
              <h3>Output</h3>
              <pre>{output}</pre>
            </div>

            <div className="errors-container">
              <h3>Errors</h3>
              {errors ? (
                <pre>{errors}</pre>
              ) : (
                <p>No errors detected.</p>
              )}
            </div>

            <div className="solutions-container">
              <h3>Solutions from StackOverflow</h3>
              {loading ? (
                <p>Loading solutions...</p>
              ) : (
                <ul>
                  {solutions.map((solution, index) => (
                    <li key={index} className="solution-card">
                      <a href={solution.link} target="_blank" rel="noopener noreferrer">
                        <strong>{solution.title}</strong>
                      </a>

                      {/* Show full solution snippet */}
                      <p>{solution.snippet}</p>

                      {/* Copy Solution Button */}
                      <button onClick={() => copyToClipboard(solution.snippet)} className="copy-btn">
                        <FaCopy /> Copy Solution
                      </button>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeEditor;
